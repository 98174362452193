<template>
	<div id="content">
		<Header v-bind:infos="infos" />
		<main>
			<!--Section caractéristiques générales-->
			<div class="container caracteristiquesGeneralesProjet">
				<div class="row">
					<!--Le commanditaire du projet-->
					<div
						class="commanditaireProjet col-sm-12 col-md-12 col-lg-4 col-xl-4"
					>
						<div>
							<h2>COMMANDITAIRE</h2>
							<p>Marc Winterstein</p>
						</div>
					</div>

					<!--La date de réalisation du projet-->
					<div
						class="dateProjet offset-lg-1 offset-xl-1 col-sm-12 col-md-12 col-lg-3 col-xl-3"
					>
						<div>
							<h2>ANNÉES</h2>
							<p>2021</p>
						</div>
					</div>

					<!--Les services apportés pour ce projet-->
					<div class="servicesProjet col-sm-12 col-md-12 col-lg-4 col-xl-4">
						<div>
							<h2>SERVICES</h2>
							<p>
								Développement front-end/back-end, webdesign, création de logo
							</p>
						</div>
					</div>
				</div>
			</div>

			<!--Section présentation du projet-->
			<div class="container">
				<div class="row">
					<p class="contenuProjet col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Le site Marc Correcteur est un projet que j'ai réalisé de façon
						indépendante pour mon père Marc Winterstein qui s'est lancé en
						juillet 2021 dans la correction et la relecture d'écrits de façon
						professionnelle. Pour cela, il m'a indiqué vouloir un site pour être
						présent sur Internet afin de proposer ses prestations.<br /><br />
						Je me suis ainsi occupé de la création du site en commençant par la
						réalisation de la maquette sur Adobe XD et la création d'un logo
						grâce à Adobe Illustrator. Marc Winterstein a voulu pour sa part
						s'occuper du contenu des pages. Je l'ai néanmoins aidé à bien
						choisir ses mots-clés afin de favoriser le référencement naturel.<br /><br />
						Le site n'étant pas très complexe et ne possédant pas beaucoup de
						pages, je l'ai réalisé en HTML | CSS | JS en utilisant le
						préprocesseur Sass qui est pratique grâce à la possibilité de créer
						des variables et des mixins pour modifier rapidement des éléments si
						besoin. Additionnellement, j'ai utilisé la grille de Bootstrap pour
						faciliter le placement des éléments et cela de façon responsive.
						Pour finir, Marc Winterstein voulant un formulaire de contact, j'ai
						utilisé du PHP pour celui-ci.
					</p>

					<div
						class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
						style="margin-top: -3vh;"
					>
						<a href="https://marc-correcteur.com/" class="boutonVoirSite"
							>LE SITE MARC CORRECTEUR</a
						>
					</div>

					<!--Image 1-->

					<img
						src="../assets/marccorecteur/maquette.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Maquette du site réalisée sur Adobe XD"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Maquette du site réalisée sur Adobe XD
					</p>
				</div>
			</div>

			<div class="container">
				<!--Bouton voir les autres projets-->
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
						<router-link to="projets" class="boutonVoirTousLesProjets"
							>VOIR LES AUTRES PROJETS</router-link
						>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
	metaInfo: function() {
		return {
			title: "Antonin Winterstein | Marc Correcteur",
			meta: [
				{
					name: "description",
					content:
						'Découvrez la création du site "Marc Correcteur" réalisé par Antonin Winterstein !',
				},
			],
		};
	},
	components: {
		Header,
	},
	data() {
		return {
			infos: {
				titrePage: "MARC CORRECTEUR - SITE WEB",
				urlImage: require("../assets/projets/fond_marc_correcteur.jpg"),
			},
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style>
@import "../styles/pageProjet.css";
</style>
